export default {
  oidc: {
    issuer: "https://oktapreview.debeersgroup.com/oauth2/aus1xx92o4siJANdS0h8",
    clientId: "0oa1yd6azhqyu6nxE0h8",
    scopes: ["openid", "profile", "email", "groups"],
    redirectUri: `${window.location.origin}`,
    pkce: true,
  },
  widget: {
    issuer: "https://oktapreview.debeersgroup.com/oauth2/aus1xx92o4siJANdS0h8",
    clientId: "0oa1yd6azhqyu6nxE0h8",
    redirectUri: `${window.location.origin}`,
    scopes: ["openid", "profile", "email", "groups"],
    useInteractionCodeFlow: "false",
    useClassicEngine: "true",
    features: {
      selfServiceUnlock: true,
    },
    i18n: {
      en: {
        'rememberDevice.timebased': 'Do not ask me for Multi Factor Authentication (MFA) again on this device for the next {0}',
        'rememberDevice.devicebased': 'Do not ask me for Multi Factor Authentication (MFA) again on this device'
      }
    }
  },
};
