import { ButtonText, SidePanelSubscriptionsText } from "../../utils/consts";
import { IconRight } from "../GlobalIcons";
import { useAuth } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";

export const SidePanelLoginForm = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const { authState } = useOktaAuth();
  const handleLogin = () => {
    if (!authState?.isAuthenticated) {
      navigate("/login");
    }
  };
  return (
    <div className="side-panel-login-form-wrapper">
      <div className="side-panel-login-form">
        <div className="subtitle heavy panel-subscriptions-text">{SidePanelSubscriptionsText}</div>
        <div className="buttons-wrapper">
          <button className="btn-primary-brown btn-wide" onClick={() => handleLogin}>
            {ButtonText.SingIn} <IconRight />
          </button>
        </div>
      </div>
    </div>
  );
};
