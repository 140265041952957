import { VideoWrapper, FooterWrapper, IconConfirm, IconRight, Loader } from "..";
import image1 from "../../assets/images/Explore-homepage-hero.jpg";
import image2 from "../../assets/images/Explore-login-page-hero.jpg";
import circleDownArrow from "../../assets/icons/DownArrowIconCircle.svg";
import { ButtonText, ExploreProducts, HeroSectionText } from "../../utils/consts";
import animationData from "../../assets/lottiefile/Explore-old.json";
import { Player } from "@lottiefiles/react-lottie-player";
import { useGetPlatforms } from "../../queries/useGetApps";
import { useAuth } from "../../hooks";
import { useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { useNavigate } from "react-router-dom";
import { Login } from "../../screens/Login";
import { render } from "react-dom";
import { decodeToken } from "@okta/okta-auth-js";
// import { useAuthOkta } from "../../hooks";

export const HeroSection = ({ handleClickOpenSidePanel }: { handleClickOpenSidePanel: () => void }) => {
  const { isLoading, data: platforms } = useGetPlatforms();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const { oktaAuth, authState } = useOktaAuth();
  const navigate = useNavigate();
  // const authOkta = useAuthOkta();

  useEffect(() => {
    setIsLoaded((isLoaded) => true);
  }, []);

  const scrollToExplore = () => {
    const item = document.querySelector(".services-wrapper h3");
    const rect = item?.getBoundingClientRect();
    if (rect) {
      window.scrollTo(0, rect.y - 150);
    }
  };
  const auth = useAuth();
  const center = authState !== null ? (!authState.accessToken?.accessToken ? "center" : "") : "center";

  if (isLoading || !Array.isArray(platforms) || !isLoaded) {
    return <Loader />;
  }
  const handleLogin = () => {
    if (!authState?.isAuthenticated) {
      window.sessionStorage.clear();
      navigate("/login");
      window.location.reload();
    }
  };
  return (
    <div>
      <div className={`hero-section ${center}`}>
        <img
          className="background-video"
          src={authState?.isAuthenticated ? image1 : image2}
          alt="Background Diamond Image"
        />
        <div className={`hero-section-body ${center}`}>          
          <div className={`text-and-video-container ${!authState?.isAuthenticated ? "sign-in-hero-section" : ""}`}>
            <div className={`hero-section-text-left ${!authState?.isAuthenticated ? "sign-in-hero-section" : ""}`}>
              <div className={"headline1"}>
                <div className={`${!authState?.isAuthenticated ? "sign-in-graphic-line" : "graphic-line"}`}></div>
                {HeroSectionText.Headline1}
              </div>
              <div className={`headline2 ${authState?.isAuthenticated ? "loggedIn-hero-section" : ""}`}>{HeroSectionText.Headline2}</div>
              <h5 className={`slogan ${authState?.isAuthenticated ? "loggedIn-hero-section" : ""}`}>{HeroSectionText.Slogan}</h5>
              {!authState?.isAuthenticated && (
                <div className="sign-in">
                  <div className="sign-in-text">{ButtonText.LandingPageSignIn}</div>
                  <div className="sign-in-btn">
                    <button className="btn-primary-brown" onClick={handleLogin}>
                      {ButtonText.Launch}
                    </button>
                  </div>
                </div>
              )}
            </div>
            {authState?.isAuthenticated && (   
              <div className="video-wrapper">
                {/* Embed Vimeo Video */}
                <div className="iframe-wrapper">
                  <iframe src="https://player.vimeo.com/video/111117121?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" 
                      allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media" 
                      className="iframe-hero-section" 
                      title="De Beers Global Sightholder Sales Relocation to Gaborone">
                  </iframe>
                </div>
                <script src="https://player.vimeo.com/api/player.js"></script>
              </div>
            )}
          </div>
          {authState?.isAuthenticated && (
            <div className="info-button-wrapper">
              <button className="btn-primary-brown" onClick={handleClickOpenSidePanel}>
                {ButtonText.Explore} <IconRight />
              </button>
              <div className="info">
                <IconConfirm /> {HeroSectionText.Products.replace("{number}", platforms?.length.toString())}
              </div>
            </div>
          )}
        </div>
        {authState?.isAuthenticated && (
          <div className="block-more">
            <div className="explore-products" onClick={scrollToExplore}> 
              <div className="circle-down-arrow">
                <img
                  className=""
                  src={circleDownArrow}
                  alt="Explore Services down arrow"
                />
                {/* <Player autoplay loop src={animationData} style={{ height: "56px", width: "56px" }}></Player> */}
              </div>
              <div className="caption-large-upper">{ExploreProducts}</div>
            </div>
          </div>
        )}        
      </div>
      <div>
        {!authState?.isAuthenticated && <FooterWrapper isSignIn={true} />}
      </div>
    </div>    
  );
};
