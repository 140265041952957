import { useNavigate } from "react-router-dom";
import { ButtonText, LogoText } from "../../utils/consts";
import { DeBeersLogo, DeBeersLogoRound, IconDown, IconLogOut, IconServices } from "..";
import logo from "../../assets/images/DBG-primary-logo-white.png";
import { useAuth } from "../../hooks";
import jwt_decode from "jwt-decode";
import { Dropdown, DropdownButton } from "react-bootstrap";
import HomeIcon from "../../assets/icons/HomeIcon.svg";
import MyAccountIcon from "../../assets/icons/MyAccountIcon.svg";
import { useOktaAuth } from "@okta/okta-react";
import { useEffect, useState } from "react";

type HeaderProps = {
  handleClickOpenSidePanel: () => void;
};

export const Header = ({ handleClickOpenSidePanel }: HeaderProps) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const { oktaAuth, authState } = useOktaAuth();
  const [user, setUser] = useState<any>(null);
  const [footerPage, setfooterPage] = useState(false);
  var [beforeLoginFooter, setBeforeLoginFooter] = useState("");
  var [footerPageStyle, setFooterPageStyle] = useState("");
  const updateDisplay = window.localStorage.getItem("updateDisplay");
  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUser(null);
    } else if (authState && window.localStorage.getItem("okta-token-storage")) {
      setUser(JSON.parse(window.localStorage.getItem("okta-token-storage") || "{}")?.idToken?.claims?.name);
    } else {
      oktaAuth
        .getUser()
        .then((info) => {
          setUser(info.name as any);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [authState, oktaAuth]); // Update if authState changes
  useEffect(() => {
    oktaAuth
      .getUser()
      .then((info) => {
        setUser(info.name as any);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [updateDisplay]);
  useEffect(() => {
    if (
      (window.location.href.includes("/termsconditions") ||
        window.location.href.includes("/privacypolicy") ||
        window.location.href.includes("/cookies")) &&
      !authState?.isAuthenticated
    ) {
      setfooterPage(true);
      setBeforeLoginFooter("beforeLoginFooter");
      setFooterPageStyle("footer-page");
    }
    if (
      (window.location.href.includes("/termsconditions") ||
        window.location.href.includes("/privacypolicy") ||
        window.location.href.includes("/cookies")) &&
      authState?.isAuthenticated
    ) {
      setBeforeLoginFooter("afterLoginFooter");
      setFooterPageStyle("afterLogin-footer-page");
    }
  }, []);
  const headerAfterLogin = authState !== null ? !authState.accessToken?.accessToken ? "" : "header-afterLogin" : "";
  const center = authState !== null ? (!authState.accessToken?.accessToken ? "center" : "") : "center";
  const Heading =
    authState !== null
      ? !authState.accessToken?.accessToken
        ? "header-logo-beforeLogin"
        : "header-logo-afterLogin"
      : "header-logo-beforeLogin";
  async function handleLogout() {
    await oktaAuth
      .signOut()
      .then(() => {
        window.sessionStorage.removeItem("subscriptions");
        window.sessionStorage.removeItem("allapps");
      })
      .catch((e) => console.log("logout error : ", e));
  }

  return (
    <div className={"main-header"}>      
      <div className={`header ${center} ${headerAfterLogin}`}>        
        <div className={`${Heading} ${footerPageStyle}`} title={LogoText} onClick={() => navigate("/")}>
          <div className="company-logo">
                  <img className="logo-medium" src={logo} alt="DeBeers Group Logo"/>
          </div>
        </div>
        <div className={"header-right-section"}>
          {(authState?.isAuthenticated || footerPage) && (
            <div className={`home-icon`} onClick={() => navigate("/")}>
              <img className="home-icon-img" src={HomeIcon} alt="Home Icon" />
            </div>
          )}
          {authState?.isAuthenticated && (
            <button className="btn-primary-trans products-opener" onClick={handleClickOpenSidePanel}>
              <IconServices />
              {/* As part of Enabler "To remove Services text and down arrow in Home page" in sprint 22, 
               removing this {ButtonText.Products} <IconDown /> */}
            </button>
          )}
          {user && (
            <DropdownButton title={user} variant={""} bsPrefix="btn-secondary-outline-white btn-capitalize">
              <Dropdown.Item onClick={() => navigate("/myaccount")} className="body-large medium">
                {ButtonText.MyAccount}
                <img src={MyAccountIcon} style={{ width: "20px", height: "22px", margin: "-3px -3px 0px -0px" }} />               
              </Dropdown.Item>
              <div className="graphic-line-logout"></div>
              <Dropdown.Item onClick={handleLogout} className="body-large medium">
                {ButtonText.LogOut}
                <IconLogOut />
              </Dropdown.Item>
            </DropdownButton>
          )}
        </div>
      </div>
    </div>
  );
};
