import OktaAuth from "@okta/okta-auth-js";
import { ServiceTypePublic } from "./consts";
import { Platform, Subscription } from "./models";
import axios from "axios";

export const copy = <T>(obj: T): T => {
  return JSON.parse(JSON.stringify(obj));
};

export const sleep = async (timeout: number) => new Promise<void>((resolve) => setTimeout(resolve, timeout));

export const filterPlatformsByVisible = (data: Platform[]): Platform[] =>
  data.filter((item) => item?.orggroup?.includes(ServiceTypePublic));

export const getFilteredServices = (allServices: Platform[], subscribedServices: Subscription[]): Platform[] =>
  allServices?.filter(
    ({ appName: allServiceName }) =>
      !subscribedServices!?.some(({ appName: subscribedServiceName }) => allServiceName === subscribedServiceName),
  );

export const convertKeysToBoolean = <T extends Record<string, any>>(obj: T, keys: (keyof T)[]): T => {
  return keys.reduce(
    (acc, key) => {
      if (key in obj) {
        return { ...acc, [key]: obj[key] === "true" ? true : false };
      }
      return acc;
    },
    { ...obj },
  );
};

const DevEnv = ["dev.explore.debeersgroup.com", "test.explore.debeersgroup.com", "localhost:3000"];
const PreprodEnv = ["preprod.explore.debeersgroup.com"];
const ProdEnv = ["explore.debeersgroup.com"];

const getIsDev = () => {
  return DevEnv.includes(window.location.host);
};

const getIsPreprod = () => {
  return PreprodEnv.includes(window.location.host);
};

const getIsProd = () => {
  return ProdEnv.includes(window.location.host);
};

const getValidateAuthenticationUrl = () => {
  const isDev = getIsDev();
  const isPreprod = getIsPreprod();
  const isProd = getIsProd();

  switch (true) {
    case isProd: {
      return "https://okta.debeersgroup.com/api/v1/sessions/me";
    }
    case isPreprod: {
      return "https://oktapreview.debeersgroup.com/api/v1/sessions/me";
    }
    case isDev:
    default: {
      return "https://oktapreview.debeersgroup.com/api/v1/sessions/me";
    }
  }
};

export const validateAuthentication = async () => {
  const url = getValidateAuthenticationUrl();
  try {
    await axios.get(url, { withCredentials: true });

    return true;
  } catch (e) {
    console.error("you are not authenticated", e);

    return false;
  }
};

export const handleLogout = async (oktaAuth: OktaAuth) => {
  await oktaAuth
    .signOut()
    .then(() => {
      window.sessionStorage.removeItem("subscriptions");
      window.sessionStorage.removeItem("allapps");
    })
    .catch((e) => console.log("logout error : ", e));
};
