import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";

import {
  Header,
  HeroSection,
  ServicesWrapper,
  SidePanelWrapper,
  TabItem,
  SidePanelPlatformsWrapper,
  SidePanelSubscriptions,
  FooterWrapper,
  Loader,
} from "../../components";
import { useGetPlatforms } from "../../queries/useGetApps";
import { useAuth } from "../../hooks";
import { filterPlatformsByVisible, getFilteredServices } from "../../utils/helpers";
import { useGetMySubscriptions } from "../../queries/useGetMySubscriptions";
import { useOktaAuth } from "@okta/okta-react";

export const Index = ({ isLandingPage = true }) => {
  const { authState } = useOktaAuth();
  const { data: mySubscriptions } = useGetMySubscriptions();
  const { isLoading, isError, data: platforms } = useGetPlatforms();
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [isHeaderDark, setIsHeaderDark] = useState(false);
  const [size, setSize] = useState(1280);
  const auth = useAuth();
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      setIsHeaderDark(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.scrollTo(0, 0);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClickOpenSidePanel = () => {
    setShowSidePanel(!showSidePanel);
  };

  useEffect(() => {
    const calculateSize = () => {
      setSize(window.innerWidth);
    };
    calculateSize();
    window.addEventListener("resize", calculateSize);
    return () => {
      window.removeEventListener("resize", calculateSize);
    };
  }, []);

  if (isLoading || isError || !Array.isArray(platforms)) {
    return <Loader hasError={isError} />;
  }

  const visiblePlatforms = !authState?.isAuthenticated ? filterPlatformsByVisible(platforms) : platforms;

  const nonSubscribedServices = getFilteredServices(visiblePlatforms, mySubscriptions!);

  const tabSwitcherData: TabItem[] = [
    {
      title: "Your subscriptions",
      content: <SidePanelSubscriptions />,
      hide: !authState!.accessToken?.accessToken,
    },
    {
      title: "All Services",
      content: <SidePanelPlatformsWrapper onClose={() => setShowSidePanel(false)} data={nonSubscribedServices} />,
    },
  ];

  return (
    <>
      <Container fluid className={`header-container ${isHeaderDark ? "dark" : ""}`}>
        <Header handleClickOpenSidePanel={handleClickOpenSidePanel} />
      </Container>
      {isLandingPage && <HeroSection handleClickOpenSidePanel={handleClickOpenSidePanel} />}
      {isLandingPage && authState?.isAuthenticated && (
        <ServicesWrapper data={visiblePlatforms} className={!authState?.isAuthenticated ? "filtered" : "full-list"} />
      )}
      {showSidePanel && (
        <SidePanelWrapper
          showSidePanel={showSidePanel}
          setShowSidePanel={setShowSidePanel}
          tabSwitcherData={tabSwitcherData}
          selectedTab={!authState!.accessToken?.accessToken ? selectedTab + 1 : selectedTab}
          setSelectedTab={setSelectedTab}
        />
      )}
      {isLandingPage && authState?.isAuthenticated && <FooterWrapper />}
    </>
  );
};
