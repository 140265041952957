import { ButtonText, SidePanelEmptySubscriptionsText, SidePanelSubscriptionsText } from "../../utils/consts";
import { IconRight, Loader, SidePanelPlatform } from "..";
import { useAuth } from "../../hooks";
import { useGetMySubscriptions } from "../../queries/useGetMySubscriptions";
import { useGetPlatforms } from "../../queries/useGetApps";
import { useOktaAuth } from "@okta/okta-react";
import { useNavigate } from "react-router-dom";

export const SidePanelSubscriptions = () => {
  const { authState } = useOktaAuth();
  const { isLoading, isError, data } = useGetMySubscriptions();
  const { isLoading: isLoadingPlatforms, isError: isErrorPlatforms, data: platforms } = useGetPlatforms();
  const auth = useAuth();
  const navigate = useNavigate();

  if (isLoading || isLoadingPlatforms || isError || isErrorPlatforms || !Array.isArray(platforms)) {
    return <Loader hasError={isError || isErrorPlatforms} />;
  }

  const list = platforms.filter((platform) => data?.findIndex((sub) => sub.appName === platform.appName) !== -1) || [];
  const handleLogin = () => {
    if (!authState?.isAuthenticated) {
      navigate("/login");
    }
  };
  return (
    <div className="panel-subscriptions">
      {authState?.isAuthenticated ? (
        list.length ? (
          list.map((platform, index) => (
            <SidePanelPlatform
              path={platform.link}
              key={index}
              image={platform.icon}
              name={platform.name}
              id={platform.id}
              appName={platform.appName}
            />
          ))
        ) : (
          <div className="subtitle medium panel-subscriptions-text">{SidePanelEmptySubscriptionsText}</div>
        )
      ) : (
        <>
          <div className="subtitle heavy panel-subscriptions-text">{SidePanelSubscriptionsText}</div>
          <div className="buttons-wrapper">
            <button className="btn-primary-brown btn-wide" onClick={() => handleLogin}>
              {ButtonText.SingIn} <IconRight />
            </button>
          </div>
        </>
      )}
    </div>
  );
};
