import { CustomCarousel, IconRight, Loader, ServicesList } from "..";
import {
  ButtonText,
  ServicesHeader,
  SidePanelSubscriptionsText,
  SubcribedServicesHeader,
  Width800Size,
  WidthSize1280,
} from "../../utils/consts";
import { Platform } from "../../utils/models";
import { useAuth } from "../../hooks";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useGetMySubscriptions } from "../../queries/useGetMySubscriptions";
import { getFilteredServices } from "../../utils/helpers";
import { useOktaAuth } from "@okta/okta-react";
import { authenticate } from "@okta/okta-auth-js";

type ServicesWrapperProps = {
  data: Platform[];
  className?: string;
};

const TileSize = 292;

export const ServicesWrapper = ({ data, className = "" }: ServicesWrapperProps) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [size, setSize] = useState(1280);
  const { oktaAuth, authState } = useOktaAuth();
  const { isLoading, isError, data: subscribedServices } = useGetMySubscriptions();

  useEffect(() => {
    const calculateSize = () => {
      setSize(window.innerWidth);
    };
    calculateSize();
    window.addEventListener("resize", calculateSize);
    return () => {
      window.removeEventListener("resize", calculateSize);
    };
  }, []);

  let items = data.length;
  if (items > 3) {
    items = 3;
  }
  if (size < WidthSize1280) {
    items = 2;
  }
  if (size < Width800Size) {
    items = 1;
  }

  if (isLoading || isError) {
    return <Loader hasError={isError} />;
  }

  const nonSubscribedServices = getFilteredServices(data, subscribedServices!);
  const handleLogin = () => {
    if (!authState?.isAuthenticated) {
      navigate("/login");
    }
  };
  return (
    <div className={`services-wrapper ${className}`}>
      {!!authState?.accessToken!.accessToken ? (
        <>
          {subscribedServices!?.length > 0 && (
            <>
              <h3 className="heading">{SubcribedServicesHeader}</h3>
              <div className="graphic-line-service-wrapper"></div>
              <div className="services-list services-bottom-spacing">
                <ServicesList data={subscribedServices!} isSubscribedService={true} /> {/* non-null assertion */}
              </div>
            </>
          )}
          <h3 className="heading">{ServicesHeader}</h3>
          <div className="graphic-line-service-wrapper"></div>
          <div className="services-list">
            <ServicesList data={nonSubscribedServices} isSubscribedService={false} />
          </div>
        </>
      ) : (
        <Row className="explore-list">
          <Col>
            <Row className="explore-data">
              <CustomCarousel minWidth={TileSize * items} singleBoxWidth={TileSize}>
                {data.map((singleService) => (
                  <Col
                    key={singleService.id}
                    className={"service-block-special"}
                    // onClick={() => navigate("/product/" + singleService.id)}
                    onClick={() => navigate("/" + singleService.appName)}
                  >
                    <div
                      className="image-block"
                      style={{
                        backgroundImage: `url(${singleService.image})`,
                      }}
                    />
                    <div className="service-info-block">
                      <h5 className="medium uppercase">{singleService.name}</h5>
                    </div>
                    <div className="service-info-block-text">
                      <span className="medium">{singleService.description.split("\n")[0]}</span>
                    </div>
                    <div className="button-wrapper">
                      <button className="btn-primary-brown btn-wide">
                        {ButtonText.Details} <IconRight />
                      </button>
                    </div>
                  </Col>
                ))}
              </CustomCarousel>
            </Row>
          </Col>
          <Col className="login-block">
            <div className="subtitle heavy panel-subscriptions-text">{SidePanelSubscriptionsText}</div>
            <div className="buttons-wrapper">
              <button className="btn-primary-brown btn-wide" onClick={() => handleLogin}>
                {ButtonText.SingIn} <IconRight />
              </button>
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};
